import 'core-js/actual/promise';
import React from 'react';
import request from 'superagent';
import ReactDOM from 'react-dom';
import WhatsOnForm from './whats_on_form';

const context = whatsonContext;

// START
$(() => {
	//moment.tz.setDefault("Australia/Sydney");
	renderAll();
});

function renderAll() {
	renderForm();
	refreshEvents();
}

window.whatsOnState = {
	form: {
		// values
		containerClass: context.formClass,
		onSelectFieldChange: handleSelectFieldChange,
		choices: {
			dateRanges: context.dateRanges,
			eventTypes: context.eventTypes,
			audiences: context.audiences,
		},
		data: {
			// default values
			dateRange: context.dateRange,
			eventType: context.eventType,
			audience: context.audience,
		},
	},
	listing: {
		class: context.listingClass,
		isLoading: true,
	},
};

function refreshEvents() {
	/* Show the events corresponding to the current form */
	whatsOnState.listing.isLoading = true;

	window.get_csrf_token_value().then((csrfToken) => {
		request
			.post(`/whatson-api/events/`)
			.type('form')
			.send({
				csrfmiddlewaretoken: csrfToken,
				date_range: whatsOnState.form.data.dateRange,
				event_type: whatsOnState.form.data.eventType,
				audience: whatsOnState.form.data.audience,
			})
			.then((result) => {
				if (result.body.success) {
					$(`.${whatsOnState.listing.class}`).html(result.body.html);
					whatsOnState.listing.isLoading = false;
				} else {
					console.log('Incorrect search');
					whatsOnState.listing.isLoading = false;
				}
			})
			.catch((err) => {
				console.log(err.message, []);
				whatsOnState.listing.isLoading = false;
			});
	});
}

function renderForm() {
	const mount_point = document.getElementsByClassName(
		whatsOnState.form.containerClass
	)[0];
	mount_form_component(mount_point);
}

function mount_form_component(mount_point) {
	ReactDOM.render(<WhatsOnForm whatsOnState={whatsOnState} />, mount_point);
}

function handleSelectFieldChange(form, fieldName, event) {
	form.data[fieldName] = event.target.value;
	updateUrlBasedOnSelection();
	renderAll();
}

function updateUrlBasedOnSelection() {
	let queryString = Object.keys(whatsOnState.form.data).reduce(function (
		result,
		key
	) {
		if (whatsOnState.form.data[key]) {
			if (result) {
				result += '&';
			}
			result += key + '=' + whatsOnState.form.data[key];
		}
		return result;
	},
	'');

	let url = window.location.pathname + '?' + queryString;
	window.history.replaceState(null, null, url);
}
