import React from 'react';
import Select from './whats_on_select';

export default class WhatsOnForm extends React.Component {

  constructor(props) {
    super(props);
    this.whatsOnState = props.whatsOnState;
  }

  render() {

    return (
      <form id="calendar-filters" className="event-calendar__filters dark-theme" action="">
        <div className="event-calendar__filter-facet event-calendar__facet--audience">
          <label className="event-calendar__filter-label">Who</label>
          <div className="event-calendar__filter-select custom-select">
            <Select
              fieldName="audience"
              options={this.whatsOnState.form.choices.audiences}
              value={this.whatsOnState.form.data.audience}
              onChange={(value) => this.whatsOnState.form.onSelectFieldChange(this.whatsOnState.form, 'audience', value)}
              />
            <span className="custom-select__indicator"></span>
          </div>
        </div>
        <div className="event-calendar__filter-facet event-calendar__facet--date">
          <label className="event-calendar__filter-label">When</label>
          <div className="event-calendar__filter-select custom-select">
            <Select
              fieldName="dateRange"
              options={this.whatsOnState.form.choices.dateRanges}
              value={this.whatsOnState.form.data.dateRange}
              onChange={(value) => this.whatsOnState.form.onSelectFieldChange(this.whatsOnState.form, 'dateRange', value)}
              />
            <span className="custom-select__indicator"></span>
          </div>
        </div>
        <div className="event-calendar__filter-facet event-calendar__facet--type">
          <label className="event-calendar__filter-label">What</label>
          <div className="event-calendar__filter-select custom-select">
            <Select
              fieldName="eventType"
              options={this.whatsOnState.form.choices.eventTypes}
              value={this.whatsOnState.form.data.eventType}
              onChange={(value) => this.whatsOnState.form.onSelectFieldChange(this.whatsOnState.form, 'eventType', value)}
              />
            <span className="custom-select__indicator"></span>
          </div>
        </div>
      </form>
    );
  }
}

// {/*<fieldset className="event-calendar__filter-facet event-calendar__facet--date-picker">*/}
//   {/*<label className="event-calendar__filter-label">When</label>*/}
//   {/*<input type="date" value="dd/mm/yyyy"/>*/}
// {/*</fieldset>*/}

// {/*<fieldset className="event-calendar__filter-facet event-calendar__filter-facet--date-picker">*/}
//   {/*<legend className="event-calendar__filter-label">When</legend>*/}
//   {/*<div className="event-calendar__filter-date-picker">*/}
//     {/*<div className="event-calendar__filter-date-facet event-calendar__filter-date-facet--month">*/}
//       {/*<label className="sr-only">Month</label>*/}
//       {/*<div className="event-calendar__filter-select custom-select">*/}
//         {/*<select>*/}
//           {/*<option>Jan</option>*/}
//           {/*<option>Feb</option>*/}
//           {/*<option>Mar</option>*/}
//           {/*<option>Apr</option>*/}
//           {/*<option>May</option>*/}
//           {/*<option>Jun</option>*/}
//           {/*<option>Jul</option>*/}
//           {/*<option>Aug</option>*/}
//           {/*<option>Sep</option>*/}
//           {/*<option>Oct</option>*/}
//           {/*<option>Nov</option>*/}
//           {/*<option>Dec</option>*/}
//         {/*</select>*/}
//         {/*<span className="custom-select__indicator"></span>*/}
//       {/*</div>*/}
//     {/*</div>*/}
//     {/*<div className="event-calendar__filter-date-facet event-calendar__filter-date-facet--day">*/}
//       {/*<label className="sr-only">Day</label>*/}
//       {/*<div className="event-calendar__filter-select custom-select">*/}
//         {/*<select>*/}
//           {/*<option>01</option>*/}
//           {/*<option>02</option>*/}
//           {/*<option>03</option>*/}
//           {/*<option>04</option>*/}
//           {/*<option>05</option>*/}
//           {/*<option>06</option>*/}
//           {/*<option>07</option>*/}
//           {/*<option>08</option>*/}
//           {/*<option>09</option>*/}
//           {/*<option>10</option>*/}
//           {/*<option>11</option>*/}
//           {/*<option>12</option>*/}
//           {/*<option>13</option>*/}
//           {/*<option>14</option>*/}
//           {/*<option>15</option>*/}
//           {/*<option>16</option>*/}
//           {/*<option>17</option>*/}
//           {/*<option>18</option>*/}
//           {/*<option>19</option>*/}
//           {/*<option>20</option>*/}
//           {/*<option>21</option>*/}
//           {/*<option>22</option>*/}
//           {/*<option>23</option>*/}
//           {/*<option>24</option>*/}
//           {/*<option>25</option>*/}
//           {/*<option>26</option>*/}
//           {/*<option>27</option>*/}
//           {/*<option>28</option>*/}
//           {/*<option>29</option>*/}
//           {/*<option>30</option>*/}
//           {/*<option>31</option>*/}
//         {/*</select>*/}
//         {/*<span className="custom-select__indicator"></span>*/}
//       {/*</div>*/}
//     {/*</div>*/}
//     {/*<div className="event-calendar__filter-date-facet event-calendar__filter-date-facet--year">*/}
//       {/*<label className="sr-only">Month</label>*/}
//       {/*<div className="event-calendar__filter-select custom-select">*/}
//         {/*<select>*/}
//           {/*<option>2018</option>*/}
//           {/*<option>2019</option>*/}
//           {/*<option>2020</option>*/}
//         {/*</select>*/}
//         {/*<span className="custom-select__indicator"></span>*/}
//       {/*</div>*/}
//     {/*</div>*/}
//     {/*<button className="event-calendar__filter-date-picker-close">Close date picker</button>*/}
//   {/*</div>*/}
// {/*</fieldset>*/}