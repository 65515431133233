import React from 'react';

export default class WhatsOnForm extends React.Component {

  constructor(props) {
    super(props);
    this.fieldName = props.fieldName;
    this.options = props.options;
    this.value = props.value;
    this.onChange = props.onChange;
  }

  render() {
    let optionsNodes = this.options.map((option) => {
      let key = this.fieldName + "-" + option.value;

      if (this.value === option.value) {
        return (<option value={option.value} key={key} selected="selected">{option.label}</option>);
      }
      else {
        return (<option value={option.value} key={key} >{option.label}</option>);
      }
    });

    return (
      <select name={this.fieldName} onChange={this.onChange} defaultValue={this.value}>
        {optionsNodes}
      </select>
    );
  }
}
